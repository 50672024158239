import { Component, Input } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { PlanVisibilitySettings } from '../../../models/plan-visibility-settings'
import { AccessoryPage } from '../../../pages/accessory/accessory-page.component'
import { PlannerPage } from '../../../pages/planner/planner-page.component'
import { Mode } from '../../../pages/planner/types/mode'
import { queryRouteComponent } from '../../../pages/planner/util/query-route-component'
import { PlanVisibilitySettingsRepository } from '../../../repositories/plan-visibility-settings.repository'
import { Capacitor } from '@capacitor/core'
import { assertEnumCaseUnreachable } from '../../../utils/assertEnumCaseUnreachable'

export enum VisibilityTypes {
  DIMENSIONS = 'dimensions',
  ANGLES = 'angles',
  GRIP_POINTS = 'grip-points',
  AUXILIARY_GUIDELINES = 'auxiliary-guidelines',
  ACCESSORY_LEGEND = 'accessory_legend',
  CYCLE_DIMENSIONS = 'cycle-dimensions',
}

@Component({
  templateUrl: './visibility-menu.component.html',
  styleUrls: ['./visibility-menu.component.scss'],
})
export class VisibilityMenuComponent {
  @Input() mode: Mode = Mode.DRAW
  @Input() planVisibilitySettings!: PlanVisibilitySettings

  isPlanner = false
  isAccessories = false
  isMobile = Capacitor.isNativePlatform()

  enumVisibilityTypes = VisibilityTypes

  constructor(
    private activatedRoute: ActivatedRoute,
    private visibilitySettingsRepo: PlanVisibilitySettingsRepository
  ) {
    this.isPlanner = queryRouteComponent(activatedRoute.snapshot, PlannerPage)
    this.isAccessories = queryRouteComponent(activatedRoute.snapshot, AccessoryPage)
  }

  async toggleVisibility(type: VisibilityTypes): Promise<void> {
    switch (type) {
      case VisibilityTypes.DIMENSIONS:
        this.planVisibilitySettings.showDimensions = !this.planVisibilitySettings.showDimensions
        break
      case VisibilityTypes.ANGLES:
        this.planVisibilitySettings.showAngles = !this.planVisibilitySettings.showAngles
        break
      case VisibilityTypes.GRIP_POINTS:
        this.planVisibilitySettings.showGripPoints = !this.planVisibilitySettings.showGripPoints
        break
      case VisibilityTypes.AUXILIARY_GUIDELINES:
        this.planVisibilitySettings.showAuxiliaryLines =
          !this.planVisibilitySettings.showAuxiliaryLines
        break
      case VisibilityTypes.ACCESSORY_LEGEND:
        this.planVisibilitySettings.showAccessoryLegend =
          !this.planVisibilitySettings.showAccessoryLegend
        break
      case VisibilityTypes.CYCLE_DIMENSIONS:
        this.planVisibilitySettings.showCycleDimensions =
          !this.planVisibilitySettings.showCycleDimensions
        break
      default:
        assertEnumCaseUnreachable(type)
    }
    await this.visibilitySettingsRepo.update(this.planVisibilitySettings)
  }

  get inDeformationMode(): boolean {
    return this.mode === Mode.DEFORMATION
  }
}
