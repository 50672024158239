<ion-item lines="none" [ngClass]="{ disabled: this.disabledAndChecked }">
  <div
    class="d-flex-col item-container"
    [ngClass]="{ 'result-container': articleItemPage === 'result' }">
    <div class="d-flex article">
      <ion-checkbox
        *ngIf="articleItemPage === 'product-catalog'"
        [checked]="selected || disabledAndChecked"
        [disabled]="this.disabledAndChecked"
        (ionChange)="toggle()"></ion-checkbox>
      <ng-container *ngIf="imgUrl$ | async as imgUrl">
        <efp-thumbnail-action-item
          [ngClass]="{ 'result-image-web': articleItemPage === 'result' }"
          [imgSrc]="imgUrl"
          [title]="article.name"
          [artNr]="article.articleId"></efp-thumbnail-action-item
      ></ng-container>

      <!-- content - title & article number & result info -->
      <div class="d-flex ion-align-items-center top-container" (click)="clickOnArticle()">
        <div class="d-flex title-info-container">
          <div class="title-container">
            <ng-container *ngIf="imgUrl$ | async as imgUrl">
              <efp-thumbnail-action-item
                *ngIf="articleItemPage === 'result'"
                [ngClass]="{ 'result-image-mobile': articleItemPage === 'result' }"
                [imgSrc]="imgUrl"
                [title]="article.name"
                [artNr]="article.articleId"></efp-thumbnail-action-item>
            </ng-container>
            <ion-note class="primary-title" text-wrap id="name">{{ article.name }}</ion-note>
          </div>

          <!-- article number -->
          <ion-note
            [ngClass]="{ 'result-page': articleItemPage === 'result' }"
            class="article-number d-flex"
            *ngIf="showArticleNumber">
            <span>{{ 'GENERAL.ARTICLE_NUMBER' | translate }}: </span>
            {{ article.articleId }}
          </ion-note>

          <!-- calculation -->
          <ion-note *ngIf="showCalculation" class="show-on-web">
            <span>{{ 'STOCK.CALCULATION.DEMAND' | translate }} (</span>{{ article.demand }}
            <span>) - {{ 'STOCK.CALCULATION.STOCK' | translate }} (</span>
            {{ article.stock }}
            <span>) = {{ 'STOCK.CALCULATION.DIFFERENCE' | translate }} (</span>
            {{ article.difference && article.difference > 0 ? article.difference : 0 }}
            <span>)</span>
          </ion-note>

          <efp-article-cycle-quantities *ngIf="showCycleQuantity" [article]="article">
            <span>{{ 'CYCLE.ARTICLE.TITLE' | translate }}: </span>
          </efp-article-cycle-quantities>
        </div>

        <!-- end slot - input + delete button -->
        <div *ngIf="showArticleAmount || deletable" class="d-flex end-container">
          <div
            *ngIf="
              showArticleAmount &&
              articleForm &&
              (articleItemPage !== 'product-catalog' || selected)
            "
            [formGroup]="articleForm"
            [ngClass]="{ groupByCycles: this.cycleNumber !== null }"
            class="d-flex ion-input-border input-container">
            <ion-input
              #articleAmount
              *ngIf="cycleNumber == null; else cycleAmount"
              id="articleAmount"
              mode="md"
              class="ml-auto difference-amount"
              type="number"
              clearOnEdit="true"
              inputmode="numeric"
              min="0"
              minlength="1"
              enterkeyhint="done"
              [efpInputRestriction]="numberValidationRegex"
              efpInputOverwritingSelect
              [formControlName]="article.articleId"
              [placeholder]="placeholderArticleAmount"
              [ngClass]="{
                'difference-amount-not-enough': !hasEnoughAmount,
                bold: wasEdited
              }"
              efpInputKeyboard
              attr.data-test-selector="stock-list-item-{{ article.articleId }}"></ion-input>

            <!-- disabled number for cycle quantity -->
            <ng-template #cycleAmount>
              <span>{{ article.cycleUsage ? article.cycleUsage[cycleNumber! - 1] : 0 }}</span>
            </ng-template>

            <!-- stepper buttons for input field -->
            <ng-container *efpShowOn="'web'">
              <div
                *ngIf="cycleNumber == null && (selected || articleItemPage !== 'product-catalog')"
                class="stepper-container">
                <ion-icon
                  (click)="increaseAmount()"
                  src="/assets/icon/stepper_button_up.svg"></ion-icon>
                <ion-icon
                  (click)="decreaseAmount()"
                  src="/assets/icon/stepper_button_down.svg"></ion-icon>
              </div>
            </ng-container>
          </div>

          <button
            class="action-icon action-icon-large"
            (click)="deleteArticle(article.articleId)"
            *ngIf="deletable && articleItemPage !== 'product-catalog'">
            delete
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="showCalculation" class="show-on-mobile">
      <!-- calculation -->
      <ion-note>
        <span>{{ 'STOCK.CALCULATION.DEMAND' | translate }} (</span>{{ article.demand }}
        <span>) - {{ 'STOCK.CALCULATION.STOCK' | translate }} (</span>
        {{ article.stock }}
        <span>) = {{ 'STOCK.CALCULATION.DIFFERENCE' | translate }} (</span>
        {{ article.difference && article.difference > 0 ? article.difference : 0 }}
        <span>)</span>
      </ion-note>
    </div>
  </div>
</ion-item>
