/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccApiClient } from './services/acc-api-client';
import { AccessoryLinesApiClient } from './services/accessory-lines-api-client';
import { AdvertisementsApiClient } from './services/advertisements-api-client';
import { AnalyticsApiClient } from './services/analytics-api-client';
import { ApiFormingApiClient } from './services/api-forming-api-client';
import { AppSettingsApiClient } from './services/app-settings-api-client';
import { ArticlesApiClient } from './services/articles-api-client';
import { BlacklistArticlesApiClient } from './services/blacklist-articles-api-client';
import { CacheApiClient } from './services/cache-api-client';
import { CatalogApiClient } from './services/catalog-api-client';
import { ChangedResultPartsApiClient } from './services/changed-result-parts-api-client';
import { CommonSettingsApiClient } from './services/common-settings-api-client';
import { CycleBoundariesApiClient } from './services/cycle-boundaries-api-client';
import { CycleSymbolsApiClient } from './services/cycle-symbols-api-client';
import { Doka360ProjectsApiClient } from './services/doka-360-projects-api-client';
import { EventsApiClient } from './services/events-api-client';
import { FavouriteProfilesApiClient } from './services/favourite-profiles-api-client';
import { FormingCalculationApiClient } from './services/forming-calculation-api-client';
import { OnboardingTrackingApiClient } from './services/onboarding-tracking-api-client';
import { PlanOutlinesApiClient } from './services/plan-outlines-api-client';
import { PlanResultApiClient } from './services/plan-result-api-client';
import { PlansApiClient } from './services/plans-api-client';
import { PlanSettingsApiClient } from './services/plan-settings-api-client';
import { PlanVisibilitySettingsApiClient } from './services/plan-visibility-settings-api-client';
import { PrivacyPolicyApiClient } from './services/privacy-policy-api-client';
import { ProjectsApiClient } from './services/projects-api-client';
import { SalesContactsApiClient } from './services/sales-contacts-api-client';
import { ScreenshotsApiClient } from './services/screenshots-api-client';
import { StocksApiClient } from './services/stocks-api-client';
import { UserApiClient } from './services/user-api-client';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccApiClient,
    AccessoryLinesApiClient,
    AdvertisementsApiClient,
    AnalyticsApiClient,
    ApiFormingApiClient,
    AppSettingsApiClient,
    ArticlesApiClient,
    BlacklistArticlesApiClient,
    CacheApiClient,
    CatalogApiClient,
    ChangedResultPartsApiClient,
    CommonSettingsApiClient,
    CycleBoundariesApiClient,
    CycleSymbolsApiClient,
    Doka360ProjectsApiClient,
    EventsApiClient,
    FavouriteProfilesApiClient,
    FormingCalculationApiClient,
    OnboardingTrackingApiClient,
    PlanOutlinesApiClient,
    PlanResultApiClient,
    PlansApiClient,
    PlanSettingsApiClient,
    PlanVisibilitySettingsApiClient,
    PrivacyPolicyApiClient,
    ProjectsApiClient,
    SalesContactsApiClient,
    ScreenshotsApiClient,
    StocksApiClient,
    UserApiClient,
    ApiConfiguration
  ],
})
export class EfpApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<EfpApiModule> {
    return {
      ngModule: EfpApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: EfpApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('EfpApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
