import { Injectable } from '@angular/core'
import { AppSettingsModel, Country, PrivacyPolicyModel } from '@efp/api'
import { createStore } from '@ngneat/elf'
import { getEntity, selectAllEntities, upsertEntities, withEntities } from '@ngneat/elf-entities'
import { BehaviorSubject, filter } from 'rxjs'
import { AppSettingsRepository } from './app-settings.repository'
import { PrivacyPoliciesDao } from '../services/dao/http/privacy-policies.dao'
import { HttpRequestSyncService, SynchronizationState } from '../services/http-request-sync.service'
import { Capacitor } from '@capacitor/core'
import {
  AppTrackingStatusResponse,
  AppTrackingTransparency,
} from 'capacitor-plugin-app-tracking-transparency'

const store = createStore(
  { name: 'privacyPolicies' },
  withEntities<PrivacyPolicyModel, 'country'>({ idKey: 'country' })
)

@Injectable({
  providedIn: 'root',
})
export class PrivacyPolicyRepository {
  private _isPrivacyPolicyAccepted$ = new BehaviorSubject<boolean | null>(null)
  public readonly isPrivacyPolicyAccepted$ = this._isPrivacyPolicyAccepted$.asObservable()

  public readonly privacyPolicies$ = store.pipe(selectAllEntities())

  constructor(
    private readonly appSettingsRepository: AppSettingsRepository,
    private readonly privacyPoliciesDao: PrivacyPoliciesDao,
    private readonly synchronizationService: HttpRequestSyncService
  ) {
    this.appSettingsRepository.appSettings$
      .pipe(filter((appsettings): appsettings is AppSettingsModel => !!appsettings))
      .subscribe((appSettings) => {
        void this.checkIfPrivacyPolicyIsAccepted(appSettings)
      })
  }

  private async checkIfPrivacyPolicyIsAccepted(appSettings: AppSettingsModel): Promise<void> {
    const privacyPolicy = await this.fetchByCountryId(appSettings.country, appSettings.language)

    if (Capacitor.getPlatform() === 'ios') {
      //TODO: 105915 remove as soon as App Tracking Transparency Issue is resolved: https://developer.apple.com/forums/thread/678920
      await this.requestAppTrackingTransparency()
    }

    if (
      appSettings.privacyPolicyAcceptedAt == null ||
      privacyPolicy.createdAt > appSettings.privacyPolicyAcceptedAt
    ) {
      this._isPrivacyPolicyAccepted$.next(false)
    } else {
      this._isPrivacyPolicyAccepted$.next(true)
    }
  }

  private async requestAppTrackingTransparency(): Promise<boolean> {
    const response = await this.requestPermission()

    if (response.status === 'authorized') {
      return true
    } else {
      return false
    }
  }

  public async requestPermission(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.requestPermission()
    return response
  }

  public async getPrivacyPolicy(): Promise<PrivacyPolicyModel> {
    const appSettings = await this.appSettingsRepository.getAppSettings()
    const country = appSettings.country
    const language = appSettings.language

    const privacyPolicy = store.query(getEntity(country))
    if (privacyPolicy) {
      return privacyPolicy
    } else {
      return this.fetchByCountryId(country, language)
    }
  }

  public async fetchByCountryId(country: Country, language: string): Promise<PrivacyPolicyModel> {
    try {
      const privacyPolicy = await this.privacyPoliciesDao.findOneByCountry(country, language)
      store.update(upsertEntities(privacyPolicy))

      return privacyPolicy
    } catch (error) {
      console.error(error)

      this.synchronizationService.synchronizationStateSubject.next(SynchronizationState.SYNC_FAILED)
    }
    const privacyPolicy = await this.privacyPoliciesDao.findOneByCountry(country, language)
    store.update(upsertEntities(privacyPolicy))

    return privacyPolicy
  }
}
