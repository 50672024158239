<div class="flex-1 toolbar">
  <ul class="flex flex-row toolbar-items md:!flex-col">
    <ng-content></ng-content>
    <ng-container *ngIf="renderService">
      <button
        efpTooltip
        [tooltipTitle]="'TOOLBAR.VISIBILITY_SETTINGS' | translate"
        class="action-icon action-icon-large"
        (click)="presentVisibilityPopover($event)"
        data-test-selector="planner-visibility-menu">
        visibility_off
      </button>
      <efp-center-button-view class="center-button-view" [model]="model"></efp-center-button-view>
    </ng-container>
  </ul>
</div>
