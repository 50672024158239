import { inject, Injectable } from '@angular/core'
import { Observable, shareReplay } from 'rxjs'
import { CommonSettings, CommonSettingsApiClient } from '../../../../generated/efp-api'

@Injectable({
  providedIn: 'root',
})
export class CommonSettingsHttpDao {
  private readonly commonSettingsApiClient = inject(CommonSettingsApiClient)

  getCommmonSettings(): Observable<CommonSettings> {
    return this.commonSettingsApiClient.getCommonSettings().pipe(shareReplay(1))
  }
}
