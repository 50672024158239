import {
  deleteEntities,
  getAllEntities,
  getAllEntitiesApply,
  getEntity,
  selectAllEntities,
  selectEntity,
  updateEntities,
  upsertEntities,
} from '@ngneat/elf-entities'
import { Injectable } from '@angular/core'
import { PlanDao } from '../services/dao/plan.dao'
import { Plan } from '../models/plan'
import { planStore } from './plan-creation.repository'
import { NavStep } from '../models/navSteps'
import { Observable } from 'rxjs'
import { PlanSettingsRepository } from './plan-settings.repository'
import {
  ApplicationInsightsService,
  ApplicationInsightsStates,
} from '../services/applicationInsights.service'
import { PlanService } from '../services/plan.service'

@Injectable({
  providedIn: 'root',
})
export class PlanRepository {
  public readonly plans$ = planStore.pipe(selectAllEntities())

  private planOffset = 0
  private planLimit = 10
  private totalPlanCount?: number

  public get hasMorePlans(): boolean {
    return this.totalPlanCount ? this.planOffset < this.totalPlanCount : false
  }

  constructor(
    private readonly planDao: PlanDao,
    readonly planSettingsRepository: PlanSettingsRepository,
    private readonly appInsightsService: ApplicationInsightsService
  ) {}

  public async updatePlan(
    plan: Pick<
      Plan,
      'id' | 'name' | 'date' | 'buildingType' | 'stockId' | 'projectId' | 'lastUsed' | 'isDoka360'
    >
  ): Promise<void> {
    const newPlan = await this.planDao.updatePlan(plan)
    planStore.update(upsertEntities(newPlan))
  }

  public async updateSerializedMesh(id: number, serializedMesh?: string): Promise<void> {
    await this.planDao.updateSerializedMesh(id, serializedMesh)
    planStore.update(updateEntities(id, { serializedMesh }))
  }

  public async updateCurrentStep(planId: number, currentStep: NavStep): Promise<void> {
    const newPlan = await this.planDao.updateCurrentStep(planId, currentStep)
    planStore.update(updateEntities(planId, newPlan))
    if (currentStep !== 'result') {
      this.appInsightsService.addUserEvent(
        ApplicationInsightsStates.PLAN_STEP_CHANGED + ' ' + currentStep,
        planId
      )
    }
  }

  public getCurrentStep(planId: number): NavStep | undefined {
    const currentStep = planStore.query(getEntity(planId))?.currentStep
    return currentStep
  }

  public getPlanSerializedMesh$(planId: number): Observable<string | undefined> {
    return planStore.pipe(selectEntity(planId, { pluck: 'serializedMesh' }))
  }

  public async findOne(planId: number): Promise<Plan | undefined> {
    let plan = planStore.query(getEntity(planId))

    if (!plan) {
      plan = await this.planDao.findOne(planId)

      if (plan) {
        planStore.update(upsertEntities(plan))
      }
    }

    return plan
  }

  public async findOneBySettingsId(settingsId: number): Promise<Plan | undefined> {
    const plans = planStore.query(
      getAllEntitiesApply({
        filterEntity: (e) => e.settingsId === settingsId,
      })
    )

    if (plans && plans.length > 0) {
      return plans[0]
    } else {
      const plan = await this.planDao.findOneBySettingsId(settingsId)

      if (plan) {
        planStore.update(upsertEntities(plan))
      }
      return plan
    }
  }

  public async findAllByProjectId(projectId: number): Promise<Plan[]> {
    const plans = await this.planDao.findAllByProjectId(projectId)
    planStore.update(upsertEntities(plans))
    return plans
  }

  public async findAllByFavouriteId(favouriteId: number): Promise<Plan[]> {
    const plans = await this.planDao.findAllByFavouriteId(favouriteId)
    planStore.update(upsertEntities(plans))
    return plans
  }

  public async fetchFirstRecentPlans(): Promise<void> {
    const storePlans = planStore.query(getAllEntities())
    if (storePlans.length > 0 && this.planOffset !== 0) {
      return
    }

    const planListModel = await this.planDao.findAllPlansWith(this.planLimit, 0)
    const plans = planListModel.plans.map(PlanService.mapPlanModelToPlan)
    planStore.update(upsertEntities(plans))

    this.planOffset += this.planLimit
    this.totalPlanCount = planListModel.totalCount
  }

  public async findNextPlans(): Promise<Plan[]> {
    const planListModel = await this.planDao.findAllPlansWith(this.planLimit, this.planOffset)
    const plans = planListModel.plans.map(PlanService.mapPlanModelToPlan)
    planStore.update(upsertEntities(plans))

    this.planOffset += this.planLimit
    this.totalPlanCount = planListModel.totalCount

    return plans
  }

  public async count(): Promise<number> {
    return this.planDao.count()
  }

  public async deleteWithAllRelated(plan: Plan): Promise<void> {
    await this.planDao.deleteWithAllRelated(plan)
    planStore.update(deleteEntities(plan.id))

    this.appInsightsService.addUserEvent(ApplicationInsightsStates.PLAN_DELETED, plan.id)
  }
}
