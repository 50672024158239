import { Injectable } from '@angular/core'
import { createStore, select, withProps } from '@ngneat/elf'
import {
  catchError,
  EMPTY,
  filter,
  finalize,
  firstValueFrom,
  map,
  of,
  retry,
  switchMap,
} from 'rxjs'
import { CommonSettings } from '../../generated/efp-api'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { CommonSettingsHttpDao } from '../services/dao/http/common-settings.http-dao'

const commonSettingsStore = createStore(
  { name: 'commonSettings' },
  withProps<CommonSettings>({ resourceUrl: '' })
)

@Injectable({
  providedIn: 'root',
})
export class CommonSettingsRepository {
  private isLoading = false
  constructor(private readonly commonSettingsService: CommonSettingsHttpDao) {
    this.isLoading = true
    this.commonSettingsService
      .getCommmonSettings()
      .pipe(
        retry({ count: 3, delay: 2000 }),
        takeUntilDestroyed(),
        filter((data): data is CommonSettings => !!data),
        catchError((error) => {
          console.error('Failed to fetch common settings:', error)
          return EMPTY
        }),
        finalize(() => {
          this.isLoading = false
        })
      )
      .subscribe((data) => {
        commonSettingsStore.update((settings: CommonSettings | null) => ({
          ...settings,
          resourceUrl: data.resourceUrl,
        }))
      })
  }

  public resourceUrl$ = commonSettingsStore.pipe(
    select((state: CommonSettings) => state.resourceUrl),
    switchMap((resourceUrl) => {
      if (resourceUrl) {
        return of(resourceUrl)
      } else {
        return this.commonSettingsService.getCommmonSettings().pipe(
          map((data) => {
            commonSettingsStore.update(() => data)
            return data.resourceUrl
          }),
          catchError((error) => {
            console.error('Failed to fetch resourceUrl:', error)
            return of('')
          })
        )
      }
    })
  )

  get resourceString(): string | null {
    const resourceUrl = commonSettingsStore.query((state) => state.resourceUrl)
    return resourceUrl || null
  }

  public async fetchResourceUrl(): Promise<string> {
    this.isLoading = true
    return firstValueFrom(this.commonSettingsService.getCommmonSettings()).then((data) => {
      this.isLoading = false
      return data.resourceUrl
    })
  }

  public async getResourceUrl(): Promise<string> {
    return this.resourceString ?? (await this.fetchResourceUrl())
  }

  public getFavImgUrl(key: string): string {
    if (!this.resourceString && !this.isLoading) void this.fetchResourceUrl()
    return this.resourceString + '/prod/FavouritesImages/' + key + '.png'
  }
}
