<ion-item
  [disabled]="disabled"
  button="true"
  lines="full"
  [mode]="mode"
  [color]="color"
  (click)="onItemClick()">
  <ng-content></ng-content>
  <div *ngIf="detailIcon" slot="end" class="detail-icon"></div>
</ion-item>
<div class="note">
  <ng-content select="[note]"></ng-content>
</div>
