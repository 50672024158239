<ion-content>
  <ion-item
    lines="none"
    *ngIf="isPlanner"
    detail="false"
    button
    (click)="toggleVisibility(enumVisibilityTypes.AUXILIARY_GUIDELINES)"
    data-test-selector="planner-visibility-menu-auxiliary-guidelines">
    <ion-toggle
      [checked]="planVisibilitySettings.showAuxiliaryLines"
      color="primary"
      labelPlacement="start"
      justify="space-between">
      <ion-icon src="assets/icon/guidelines.svg"></ion-icon>
      <span>
        {{ 'SETTINGS.VISIBILITY.GUIDELINES' | translate }}
      </span>
    </ion-toggle>
  </ion-item>
  <ion-item
    lines="none"
    *ngIf="inDeformationMode"
    detail="false"
    button
    (click)="toggleVisibility(enumVisibilityTypes.GRIP_POINTS)"
    data-test-selector="planner-visibility-menu-grid-points">
    <ion-toggle
      [checked]="planVisibilitySettings.showGripPoints"
      color="primary"
      labelPlacement="start"
      justify="space-between">
      <ion-icon src="assets/icon/meshpoints.svg"></ion-icon>
      <span>
        {{ 'SETTINGS.VISIBILITY.MESHPOINTS' | translate }}
      </span>
    </ion-toggle>
  </ion-item>
  <ion-item
    lines="none"
    detail="false"
    button
    (click)="toggleVisibility(enumVisibilityTypes.ANGLES)"
    data-test-selector="planner-visibility-menu-angles">
    <ion-toggle
      [checked]="planVisibilitySettings.showAngles"
      color="primary"
      labelPlacement="start"
      justify="space-between">
      <ion-icon src="assets/icon/angle.svg"></ion-icon>
      <span>
        {{ 'SETTINGS.VISIBILITY.ANGLES' | translate }}
      </span>
    </ion-toggle>
  </ion-item>
  <ion-item
    lines="none"
    detail="false"
    button
    (click)="toggleVisibility(enumVisibilityTypes.DIMENSIONS)"
    data-test-selector="planner-visibility-menu-dimensions">
    <ion-toggle
      [checked]="planVisibilitySettings.showDimensions"
      color="primary"
      labelPlacement="start"
      justify="space-between">
      <ion-icon src="assets/icon/dimensions.svg"></ion-icon>
      <span>
        {{ 'SETTINGS.VISIBILITY.DIMENSIONS' | translate }}
      </span>
    </ion-toggle>
  </ion-item>

  <ion-item
    lines="none"
    detail="false"
    button
    (click)="toggleVisibility(enumVisibilityTypes.CYCLE_DIMENSIONS)"
    data-test-selector="planner-visibility-menu-dimensions">
    <ion-toggle
      [checked]="planVisibilitySettings.showCycleDimensions"
      color="primary"
      labelPlacement="start"
      justify="space-between">
      <span class="icon-2xl">counter_1</span>
      <span>
        {{ 'SETTINGS.VISIBILITY.CYCLE_DIMENSIONS' | translate }}
      </span>
    </ion-toggle>
  </ion-item>

  <ion-item
    lines="none"
    detail="false"
    button
    *ngIf="isAccessories"
    (click)="toggleVisibility(enumVisibilityTypes.ACCESSORY_LEGEND)"
    data-test-selector="planner-visibility-menu-dimensions">
    <ion-toggle
      [checked]="planVisibilitySettings.showAccessoryLegend"
      color="primary"
      labelPlacement="start"
      justify="space-between">
      <ion-icon src="assets/icon/ic_text-box-outline.svg"></ion-icon>
      <span> {{ 'SETTINGS.VISIBILITY.LEGEND' | translate }}</span>
    </ion-toggle>
  </ion-item>
</ion-content>
