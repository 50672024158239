/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseApiClient } from '../base-api-client';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { PlanListModel } from '../models/plan-list-model';

@Injectable({ providedIn: 'root' })
export class Doka360ProjectsApiClient extends BaseApiClient {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPlansForDoka360ProjectUid()` */
  static readonly GetPlansForDoka360ProjectUidPath = '/api/v1/doka360projects/{doka360ProjectsUid}/plans';

  /**
   * Get plans for Doka360Project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlansForDoka360ProjectUid$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlansForDoka360ProjectUid$Plain$Response(
    params: {
      doka360ProjectsUid: string;
      limit?: number;
      offset?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlanListModel>> {
    const rb = new RequestBuilder(this.rootUrl, Doka360ProjectsApiClient.GetPlansForDoka360ProjectUidPath, 'get');
    if (params) {
      rb.path('doka360ProjectsUid', params.doka360ProjectsUid, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanListModel>;
      })
    );
  }

  /**
   * Get plans for Doka360Project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlansForDoka360ProjectUid$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlansForDoka360ProjectUid$Plain(
    params: {
      doka360ProjectsUid: string;
      limit?: number;
      offset?: number;
    },
    context?: HttpContext
  ): Observable<PlanListModel> {
    return this.getPlansForDoka360ProjectUid$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanListModel>): PlanListModel => r.body)
    );
  }

  /**
   * Get plans for Doka360Project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPlansForDoka360ProjectUid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlansForDoka360ProjectUid$Response(
    params: {
      doka360ProjectsUid: string;
      limit?: number;
      offset?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PlanListModel>> {
    const rb = new RequestBuilder(this.rootUrl, Doka360ProjectsApiClient.GetPlansForDoka360ProjectUidPath, 'get');
    if (params) {
      rb.path('doka360ProjectsUid', params.doka360ProjectsUid, {});
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanListModel>;
      })
    );
  }

  /**
   * Get plans for Doka360Project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPlansForDoka360ProjectUid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPlansForDoka360ProjectUid(
    params: {
      doka360ProjectsUid: string;
      limit?: number;
      offset?: number;
    },
    context?: HttpContext
  ): Observable<PlanListModel> {
    return this.getPlansForDoka360ProjectUid$Response(params, context).pipe(
      map((r: StrictHttpResponse<PlanListModel>): PlanListModel => r.body)
    );
  }

}
