import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { TranslateModule } from '@ngx-translate/core'
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer'
import { CenterButtonViewComponent } from '../pages/planner/components/center-button-view/center-button-view.component'
import { AccordionGroupControlComponent } from './components/accordion-group-control/accordion-group-control.component'
import { AccordionGroupComponent } from './components/accordion-group/accordion-group.component'
import { AngleInputComponent } from './components/angle-input/angle-input.component'
import { AppHeaderComponent } from './components/app-header/app-header.component'
import { ArticleCycleQuantitiesComponent } from './components/article-cycle-quantities/article-cycle-quantities.component'
import { ArticleItemComponent } from './components/article-item/article-item.component'
import { ArticleListComponent } from './components/article-list/article-list.component'
import { ContextMenuCenterButtonContainerComponent } from './components/context-menu-center-button-container/context-menu-center-button-container.component'
import { ContextMenuContainerComponent } from './components/context-menu/context-menu-container.component'
import { CustomSegmentButtonComponent } from './components/custom-segment-button/custom-segment-button.component'
import { CycleMenu3DComponent } from './components/cycle-menu-3d/cycle-menu-3d.component'
import { DrawingsettingsFormComponent } from './components/drawingsettings-form/drawingsettings-form.component'
import { EfpBottomMenuContainerComponent } from './components/efp-bottom-menu-container/efp-bottom-menu-container.component'
import { EfpCenterContainerComponent } from './components/efp-center-container/efp-center-container.component'
import { EfpListDividerItemComponent } from './components/efp-list-divider-item/efp-list-divider-item.component'
import { EfpListHeaderComponent } from './components/efp-list-header/efp-list-header.component'
import { EfpSidebarContainerComponent } from './components/efp-sidebar-container/efp-sidebar-container.component'
import { EfpSidebarFilterSectionComponent } from './components/efp-sidebar-filter-section/efp-sidebar-filter-section.component'
import { EfpTopbarFilterContainerComponent } from './components/efp-topbar-filter-container/efp-topbar-filter-container.component'
import { EfpWebMobileButtonBarComponent } from './components/efp-web-mobile-button-bar/efp-web-mobile-button-bar.component'
import { FloatingButtonBottomComponent } from './components/floating-button-bottom/floating-button-bottom.component'
import { FormworkSettingsComponent } from './components/formwork-settings/formwork-settings.component'
import { HighlightMenu3DComponent } from './components/highlight-menu-3d/highlight-menu-3d.component'
import { KeypadComponent } from './components/keypad/keypad.component'
import { LengthInputComponent } from './components/length-input/length-input.component'
import { ListAccordionComponent } from './components/list-accordion/list-accordion.component'
import { ModalBaseComponent } from './components/modal-base/modal-base.component'
import { NavbarComponent } from './components/navbar/navbar.component'
import { OnboardingHintContentComponent } from './components/onboarding-hints/onboarding-hint-content/onboarding-hint-content.component'
import { OnboardingHintsComponent } from './components/onboarding-hints/onboarding-hints.component'
import { PageHeadingComponent } from './components/page-heading/page-heading.component'
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component'
import { PlansettingsFormComponent } from './components/plansettings-form/plansettings-form.component'
import { PrimaryPlannerToolbarComponent } from './components/primary-planner-toolbar/primary-planner-toolbar.component'
import { ToolbarDividerComponent } from './components/primary-planner-toolbar/toolbar-divider/toolbar-divider.component'
import { RequestSyncIndicatorComponent } from './components/request-sync-indicator/request-sync-indicator.component'
import { SafeAreaBottomComponent } from './components/safe-area-bottom/safe-area-bottom.component'
import { SafeAreaTopComponent } from './components/safe-area-top/safe-area-top.component'
import { ScrollbarComponent } from './components/scrollbar/scrollbar.component'
import { SearchBarComponent } from './components/search-bar/search-bar.component'
import { StockFileUploadComponent } from './components/stock-file-upload/stock-file-upload.component'
import { ToggleCellComponent } from './components/toggle-cell/toggle-cell.component'
import { UndoRedoComponent } from './components/undo-redo/undo-redo.component'
import { VisibilityMenu3DComponent } from './components/visibility-menu-3d/visibility-menu-3d.component'
import { VisibilityMenuComponent } from './components/visibility-menu/visibility-menu.component'
import { EfpHideOnMobileDirective } from './directives/hide-on-mobile.directive'
import { InputKeyboardDirective } from './directives/input-keyboard.directive'
import { InputOverwritingSelectDirective } from './directives/input-overwriting-select.directive'
import { InputRestrictionDirective } from './directives/input-restriction.directive'
import { KeyboardShortcutDirective } from './directives/keyboard-shortcut.directive'
import { OnboardingTriggerDirective } from './directives/onboarding-trigger.directive'
import { RecreateViewDirective } from './directives/recreate-view.directive'
import { EfpShowOnDirective } from './directives/show-on.directive'
import { FormworkPlannerModule } from './formwork-planner'
import { EfpDatePipe } from './pipes/efp-date.pipe'
import { FavouritesPipe } from './pipes/favourites/favourites.pipe'
import { LengthPipe } from './pipes/length/length.pipe'
import { KeysPipe } from './pipes/keys.pipe'
import { BadgeComponent } from './components/badge/badge.component'
import { EfpListTileStylingComponent } from './components/efp-list-tile-styling/efp-list-tile-styling.component'
import { FormworkSelectedSettingsComponent } from './components/formwork-selected-settings/formwork-selected-settings.component'
import { PrimaryMenuComponent } from './components/primary-menu/primary-menu.component'
import { CollapsableMenuComponent } from './components/collapsable-menu/collapsable-menu.component'
import { OpenMenuButtonComponent } from './components/open-menu-button/open-menu-button.component'
import { ModalFormComponent } from './components/modal-form/modal-form.component'
import { TimeAgoPipe } from './pipes/timeAgo.pipe'
import { MeasurementLegendComponent } from './formwork-planner/components/measurement-legend/measurement-legend'
import { TooltipDirective } from './directives/tooltip.directive'
import { FeedbackComponent } from './components/feedback/feedback.component'
import { NgxCaptureModule } from 'ngx-capture'
import { ExclusionSettingsComponent } from './components/exclusion-settings/exclusion-settings.component'
import { ResetOnboardingModalComponent } from './components/onboarding-hints/reset-onboarding/reset-onboarding-modal.component'
import { ResetOnboardingButtonComponent } from './components/onboarding-hints/reset-onboarding/reset-onboarding-button/reset-onboarding-button.component'
import { OnboardingHintTemplateComponent } from './components/onboarding-hints/onboarding-hint-template/onboarding-hint-template.component'
import { ThumbnailActionItemComponent } from './components/thumbnail-action-item/thumbnail-action-item.component'
import { HoverPortalDirective } from './directives/hover-portal.directive'
import { ThumbnailPreviewComponent } from './components/thumbnail-preview/thumbnail-preview.component'
import { AccSettingsComponent } from './components/acc-settings/acc-settings.component'
import { ContactComponent } from './components/contact/contact.component'
import { MultiStepModalComponent } from './components/multi-step-modal/multi-step-modal.component'
import { StepsModalFooterComponent } from './components/multi-step-modal/steps-modal-footer/steps-modal-footer.component'
import { AdvertisementDirective } from './directives/advertisement.directive'
import { UploadProgressComponent } from './upload-progress/upload-progress.component'
import { PrivacyPolicyModalComponent } from './components/privacy-policy-modal/privacy-policy-modal.component'
import { EditorOverlayContainerComponent } from '../pages/planner/components/overlay-editor-container/overlay-editor-container.component'
import { NumberIndicatorComponent } from './components/number-indicator/number-indicator.component'

const PUBLIC_COMPONENTS = [
  ContextMenuContainerComponent,
  PdfViewerComponent,
  LengthPipe,
  FavouritesPipe,
  EfpDatePipe,
  DrawingsettingsFormComponent,
  LengthInputComponent,
  FormworkSettingsComponent,
  CenterButtonViewComponent,
  NavbarComponent,
  PlansettingsFormComponent,
  AppHeaderComponent,
  PrimaryPlannerToolbarComponent,
  VisibilityMenuComponent,
  VisibilityMenu3DComponent,
  HighlightMenu3DComponent,
  ToggleCellComponent,
  InputRestrictionDirective,
  UndoRedoComponent,
  InputOverwritingSelectDirective,
  SafeAreaBottomComponent,
  SafeAreaTopComponent,
  KeypadComponent,
  OnboardingHintsComponent,
  OnboardingHintContentComponent,
  OnboardingTriggerDirective,
  ModalBaseComponent,
  InputKeyboardDirective,
  EfpListDividerItemComponent,
  EfpListHeaderComponent,
  EfpCenterContainerComponent,
  EfpWebMobileButtonBarComponent,
  KeyboardShortcutDirective,
  EfpBottomMenuContainerComponent,
  ArticleItemComponent,
  ArticleListComponent,
  PageHeadingComponent,
  StockFileUploadComponent,
  RequestSyncIndicatorComponent,
  EfpSidebarContainerComponent,
  EfpHideOnMobileDirective,
  ContextMenuCenterButtonContainerComponent,
  ScrollbarComponent,
  EfpShowOnDirective,
  AngleInputComponent,
  EfpSidebarFilterSectionComponent,
  FloatingButtonBottomComponent,
  EfpTopbarFilterContainerComponent,
  ListAccordionComponent,
  AccordionGroupComponent,
  ArticleCycleQuantitiesComponent,
  CustomSegmentButtonComponent,
  CycleMenu3DComponent,
  AccordionGroupControlComponent,
  KeysPipe,
  BadgeComponent,
  EfpListTileStylingComponent,
  SearchBarComponent,
  FormworkSelectedSettingsComponent,
  PrimaryMenuComponent,
  CollapsableMenuComponent,
  OpenMenuButtonComponent,
  ModalFormComponent,
  TimeAgoPipe,
  MeasurementLegendComponent,
  CollapsableMenuComponent,
  OpenMenuButtonComponent,
  ToolbarDividerComponent,
  TooltipDirective,
  ExclusionSettingsComponent,
  RecreateViewDirective,
  FeedbackComponent,
  ResetOnboardingModalComponent,
  ResetOnboardingButtonComponent,
  OnboardingHintTemplateComponent,
  ThumbnailActionItemComponent,
  HoverPortalDirective,
  ThumbnailPreviewComponent,
  AccSettingsComponent,
  ContactComponent,
  MultiStepModalComponent,
  StepsModalFooterComponent,
  AdvertisementDirective,
  UploadProgressComponent,
  PrivacyPolicyModalComponent,
  EditorOverlayContainerComponent,
  NumberIndicatorComponent,
]

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    NgxExtendedPdfViewerModule,
    FormworkPlannerModule,
    RouterModule,
    NgxCaptureModule,
  ],
  exports: [PUBLIC_COMPONENTS],
  declarations: [PUBLIC_COMPONENTS],
  providers: [],
})
export class SharedModule {}
